import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// config
import { FIREBASE_API } from 'src/config-global';
//
import userService from 'src/services/user-service';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);
AUTH.settings.appVerificationDisabledForTesting = false;

export const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        console.log('onAuthStateChanged', user);
        let profile = {
          displayName: null,
          email: null,
        };

        if (user) {
          const resp = await userService.getUser(user.uid);
          console.log('response', resp);
          if (resp.displayName && resp.email) {
            profile = resp;
          }

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(AUTH, 'recaptcha-container', {
        size: 'invisible', // Use 'invisible' to automatically verify, or 'normal' for visible
        callback: (response) => {
          console.log('reCAPTCHA resolved with response:', response);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, please solve it again');
        },
      });
    }
  };

  const loginWithPhoneNumber = useCallback(async (phoneNumber, setError, setLoading) => {
    setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    console.log('appVerifier', appVerifier);
    setLoading(true);

    try {
      const confirmationResult = await signInWithPhoneNumber(AUTH, phoneNumber, appVerifier);
      console.log('confirmationResult', confirmationResult);
      window.confirmationResult = confirmationResult;
      console.log('SMS sent successfully');
      setLoading(false);
    } catch (error) {
      console.error('Error during sign-in with phone number', error);
      setError('Failed to send OTP. Please try again.');
      setLoading(false);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      logout,
      loginWithPhoneNumber,
    }),
    [
      status,
      state.user,
      //
      logout,
      loginWithPhoneNumber,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
