import { COUNTRY_DETAILS_MAP } from './_country_details_map';

export const COUNTRY_CHECKOUT_STEPS = ['Dates', 'Photo', 'Passport', 'Detail', 'Checkout'];
export const APPLICATION_STATUS_STEPS = [
  'Application',
  'Document collection',
  'Processing',
  'Visa Granted',
];

export const ADD_CO_TRAVELLER_STEPS = ['Photo', 'Passport', 'Detail'];

export const DEFAULT_DEPARTURE_COUNTRY = { name: 'India', iso2_code: 'IN' };

export const DATE_CLARITY_TYPES = ['fixed', 'flexible', 'asap'];

export const COUNTRIES = Object.values(COUNTRY_DETAILS_MAP)
  .reduce((acc, c) => {
    acc.push({
      code: c.code,
      priority: c.priority ?? 10000,
      name: c.name, // Changed from c.country_name to match your sample
      price: c.price || 0, // Ensure a default value
      hero_image_url: `/assets/images/countries/${c.code}.jpg`,
      sticker_visa: c.sticker_visa || false,
      supported: c.supported,
      purpose: c.purpose || 'unspecified', // Add default purpose if missing
      visa_free_message: c.visa_free_message || '',
      packages: c.packages || [],
    });

    return acc;
  }, [])
  .sort((a, b) => {
    // Combine price and priority sorting into a single pass
    if (a.priority !== b.priority) return a.priority - b.priority;
    return b.price - a.price;
  });
